// COPY PATHS

const phone = document.getElementById('phoneLink')
const mail = document.getElementById('mailLink')
const copyClick = document.getElementById('copy')

function copy(path) {
    path ? navigator.clipboard.writeText(path) : console.log('error copy');
}
function showText() {
    copyClick.classList.add('down')
    setTimeout(() => {
        copyClick.classList.remove('down')
    }, 2500)
}
phone.addEventListener('click', () => {
    copy('0638387707')
    showText()
})
mail.addEventListener('click', () => {
    copy('hugodelbegue@gmail.com')
    showText()
})