// CAROUSEL

import { isMobileDevice } from "../utils/mobileDetect"

const carousel = document.querySelector('.carousel')
const container = document.getElementById('switchBlock')
const buttonRight = document.querySelector('.buttonRight')
const buttonLeft = document.querySelector('.buttonLeft')
const pictures = document.querySelectorAll('.picture')
const points = document.querySelectorAll('.points')
const mediaQuery = window.matchMedia("(min-width: 790px)")
const mediaQueryUp = window.matchMedia("(min-width: 1440px)")

let p = 0;
const lastIndex = pictures.length - 1;
document.buttonsDisplay = () => {
    if (p === lastIndex) {
        styleButtonHidden(buttonRight)
        buttonLeft.style.animationName = "scale";
    } else {
        styleButtonVisisble(buttonRight)
        buttonLeft.style.animationName = "";
    }
    if (p === 0) {
        styleButtonHidden(buttonLeft)
        buttonRight.style.animationName = "scale";
    } else {
        styleButtonVisisble(buttonLeft)
        buttonRight.style.animationName = "";
    }
    points.forEach((point, index) => {
        if (p === index) {
            point.style.backgroundColor = 'var(--orange)';
            point.style.transform = 'scale(1)';
        } else {
            point.style.backgroundColor = '';
            point.style.transform = 'scale(.6)';
        }
        if (p === (index + 1)) {
            point.style.backgroundColor = 'var(--white-light-opaque)';
            point.style.transform = 'scale(.7)';
        } else if (p === (index - 1)) {
            point.style.backgroundColor = 'var(--white-light-opaque)';
            point.style.transform = 'scale(.7)';
        }
    });
    pictures.forEach((item, index) => {
        if (p === index) {
            item.style.border = '4px solid var(--gray-light)'
            item.style.transform = 'scale(1)'
        } else {
            item.style.border = '4px solid transparent'
            if (mediaQueryUp.matches) {
                item.style.transform = 'scale(.85)'
            } else if (mediaQuery.matches) {
                item.style.transform = 'scale(.8)'
            } else {
                item.style.transform = 'scale(.75)'
            }
        }
    })
}
document.body.onload = () => {
    document.buttonsDisplay()
}
let itemVisible = 1;
let ratio = pictures.length / itemVisible;
container.style.width = (ratio * 100) + "%";
for (let i = 0; i < pictures.length; i++) {
    pictures[i].style.width = ((100 / (itemVisible)) / ratio) + "%";
}
const next = () => {
    if (p < lastIndex) {
        p++;
        goToPicture(p)
    } else {
        goToPicture(lastIndex)
    }
    enableTransition()
    document.buttonsDisplay()
}
const previous = () => {
    if (p > 0) {
        p--;
        goToPicture(p)
    } else {
        goToPicture(0)
    }
    enableTransition()
    document.buttonsDisplay()
}
buttonRight.addEventListener("click", next)
buttonLeft.addEventListener("click", previous)
if (isMobileDevice()) {
    buttonRight.style.display = "none"
    buttonLeft.style.display = "none"
}

const styleButtonHidden = (sideButton) => {
    sideButton.style.opacity = "0";
    sideButton.style.visibility = "hidden";
}
const styleButtonVisisble = (sideButton) => {
    sideButton.style.opacity = "1";
    sideButton.style.visibility = "visible";
}

// TACTIL

container.addEventListener("dragstart", (e) => { e.preventDefault() })
container.addEventListener("mousedown", stardDrag)
container.addEventListener("touchstart", stardDrag)
window.addEventListener("mousemove", drag)
window.addEventListener("touchmove", drag)
window.addEventListener("mouseup", endDrag)
window.addEventListener("touchend", endDrag)
window.addEventListener("touchcancel", endDrag)

let origin;
let width;
let lastTranslate;

function stardDrag(e) {
    if (e.touches) {
        e = e.touches[0]
    }
    if (e.target.classList.contains('zoom_button')) {
        return
    } else {
        origin = { x: e.screenX, y: e.screenY }
        disableTransition()
        width = containerWidth()
    }
}

function drag(e) {
    if (origin) {
        let point = e.touches ? e.touches[0] : e
        let translate = { x: point.screenX - origin.x, y: point.screenY - origin.y }
        if (e.touches && Math.abs(translate.x) > Math.abs(translate.y)) {
            e.preventDefault()
            e.stopPropagation()
        }
        let baseTranslate = p * -100 / pictures.length
        lastTranslate = translate
        translatePicture(baseTranslate + 100 * translate.x / width)
    }
}


function endDrag() {
    if (origin && lastTranslate) {
        enableTransition()
        if (Math.abs(lastTranslate.x / carouselWidth()) > 0.1) {
            if (lastTranslate.x < 0) {
                next()
            } else {
                previous()
            }
        } else {
            goToPicture(p)
        }
    }
    origin = null
}

const goToPicture = (currentPicture) => {
    let x = currentPicture * -100 / pictures.length;
    translatePicture(x)
}

const translatePicture = (percent) => {
    container.style.transform = "translate3d(" + percent + "%, 0, 0)"
}

const disableTransition = () => {
    container.style.transition = "none"
}
const enableTransition = () => {
    container.style.transition = "all 0.25s"
}

const containerWidth = () => {
    return container.offsetWidth
}

const carouselWidth = () => {
    return carousel.offsetWidth
}