// TOP RETURN BUTTON

const button = document.getElementById('button');
function returnTop() {
    const top = document.getElementById('top');
    top.scrollIntoView({ behavior: 'smooth' });
}
window.addEventListener("scroll", () => {
    if (window.scrollY < 1000) {
        button.classList.add("hide");
        button.classList.remove("show");
    } else {
        button.classList.add("show");
        button.classList.remove("hide");
    }
    let timer = null;
    window.addEventListener('scroll', () => {
        if (timer !== null) {
            clearTimeout(timer);
            button.style.backgroundColor = "rgba(0, 0, 0, .7)";
        }
        timer = setTimeout(() => {
            button.style.backgroundColor = "rgba(0, 0, 0, .2)";
        }, 2000);
    }, false);
})
button.addEventListener("click", () => {
    returnTop();
})