// CONTACT FORM

const form = document.getElementById('form')
const success = document.getElementById('success')
const errorText = document.getElementById('error')
const nameInput = document.querySelector('.name')
const emailInput = document.querySelector('.email')
const messageInput = document.querySelector('.message')

function showText() {
    errorText.classList.add('down')
    setTimeout(() => {
        errorText.classList.remove('down')
    }, 2500)
}
function resetError() {
    const inputs = [nameInput, emailInput, messageInput];
    inputs.forEach(input => {
        input.classList.remove('error')
    })
}
// Send form
function sendEmail(name, email, number, message) {
    const serviceId = 'service_kj8g0gs';
    const templateId = 'template_uywc7uk';
    const publicKey = 'qH3WgmDhYRov4CSQq';
    let errors = []
    resetError()
    if (!name) {
        errors.push('Nom')
        nameInput.classList.add('error')
    }
    if (!email) {
        errors.push('Email')
        emailInput.classList.add('error')
    }
    if (!message) {
        errors.push('Message')
        messageInput.classList.add('error')
    }
    if (errors.length === 0 && name && email && message) {
        try {
            emailjs.sendForm(serviceId, templateId, '#form', publicKey);
            console.log('Success');
            success.classList.add('down')
        } catch (error) {
            console.error('Erreur lors de l\'envoi.', error.text);
        } finally {
            form.reset()
            resetError()
            errors = []
            setTimeout(() => {
                success.classList.remove('down')
            }, 2500)
        }
    } else {
        showText()
        console.error(`Les champs obligatoires sont manquants: ${errors.join(', ')}`);
    }
}
form.addEventListener('submit', (e) => {
    e.preventDefault()
    const name = form.elements.name.value
    const email = form.elements.email.value
    const number = form.elements.number.value
    const message = form.elements.message.value
    sendEmail(name, email, number, message)
})