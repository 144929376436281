// SCROLL ANIMATIONS

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
if (ScrollTrigger.isTouch !== 1) {
    ScrollSmoother.create({
        wrapper: '.wrapper',
        content: '.content',
        smooth: 1.5,
        effects: true,
    })

    gsap.fromTo('.header', { opacity: 1 }, {
        opacity: 0,
        scrollTrigger: {
            trigger: '.header',
            start: 'center',
            end: '900',
            scrub: true
        }
    })

    let itemLeft = gsap.utils.toArray('.gallery_left .offset');
    itemLeft.forEach(item => {
        gsap.fromTo(item, { x: -100, opacity: 0, duration: 0.3 }, {
            opacity: 1,
            x: 0,
            scrollTrigger: {
                trigger: item,
                start: 'top bottom',
                end: 'bottom bottom',
                scrub: 1
            }
        })
    });

    let itemRight = gsap.utils.toArray('.gallery_right .offset');
    itemRight.forEach(item => {
        gsap.fromTo(item, { x: 100, opacity: 0 }, {
            opacity: 1,
            x: 0,
            scrollTrigger: {
                trigger: item,
                start: 'top bottom',
                end: 'bottom bottom',
                scrub: 2
            }
        })
    });
}