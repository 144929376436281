// ZOOM PICTURE

const drawings = document.getElementsByClassName('gallery_drawing')
const sketch = document.getElementsByClassName('picture')
const bgZoom = document.getElementById('background_zoom')
const buttonZoom = document.getElementsByClassName('zoom_button')
const cross = document.getElementById('close')
function zoom(imageUrl) {
    const image = new Image();
    image.src = imageUrl;
    image.addEventListener("load", function () {
        bgZoom.style.backgroundImage = "url('" + imageUrl + "')";
    });
    image.addEventListener("error", function () {
        close()
    });
    bgZoom.style.display = "flex"
    document.body.style.overflow = "hidden"
}
function close() {
    bgZoom.style.display = ""
    bgZoom.style.backgroundOrigin = ""
    bgZoom.style.backgroundImage = ""
    document.body.style.overflow = ""
}
for (let i = 0; i < drawings.length; i++) {
    const url = drawings[i].childNodes[1].src
    drawings[i].addEventListener("click", () => {
        zoom(url);
    })
}
for (let i = 0; i < sketch.length; i++) {
    const url = sketch[i].childNodes[1].src
    const button = sketch[i].childNodes[3]
    button.addEventListener("click", (e) => {
        e.stopPropagation()
        zoom(url)
    })
}
bgZoom.addEventListener('click', (event) => {
    const style = getComputedStyle(bgZoom);
    const propriete = style.getPropertyValue('background-origin')
    if (!cross.contains(event.target)) {
        if (propriete == 'content-box') {
            bgZoom.style.backgroundOrigin = 'padding-box'
            bgZoom.style.cursor = 'zoom-out'
        } else {
            bgZoom.style.backgroundOrigin = 'content-box'
            bgZoom.style.cursor = 'zoom-in'
        }
    }
});
cross.addEventListener("click", () => {
    close();
})




