// BOTTOM ARROW

import { isMobileDevice } from "../utils/mobileDetect"

const arrow = document.getElementById('arrow')
const anchor = document.getElementById('arrow_anchor')
const galleryLeft = document.querySelector('.gallery_left')
const itemGalleryLeft = document.querySelectorAll('.gallery_left .gallery_item')
const galleryRight = document.querySelector('.gallery_right')
const itemGalleryRight = document.querySelectorAll('.gallery_right .gallery_item')
const galleryProfil = document.querySelector('.gallery_profil')
const galleryBonus = document.querySelector('.gallery_bonus')

function offsetDown(pixel) {
    if (document.documentElement.scrollTo) {
        document.documentElement.scrollTo({
            top: document.documentElement.scrollTop + pixel,
            behavior: 'smooth'
        });
    } else {
        window.scrollTo(0, document.body.scrollTop + pixel);
    }
}
function offsetAnchor() {
    anchor.scrollIntoView({ behavior: 'smooth' });
}
function pixelDown() {
    if (window.scrollY > 50) {
        arrow.classList.add("hide");
        arrow.classList.remove("show");
    } else {
        arrow.classList.add("show");
        arrow.classList.remove("hide");
    }
}
window.addEventListener("load", () => {
    pixelDown();
    if (isMobileDevice()) {
        itemGalleryLeft.forEach(item => {
            item.style.marginTop = "var(--gallery-gap-mobile)"
        });
        galleryLeft.style.placeContent = "space-between"
        itemGalleryRight.forEach(item => {
            item.style.marginTop = "var(--gallery-gap-mobile)"
        });
        galleryRight.style.placeContent = " space-between"
        galleryLeft.style.marginTop = "0"
        galleryProfil.style.marginTop = "0"
        galleryProfil.style.marginTop = "var(--gallery-gap-mobile)"
        galleryBonus.style.display = "flex"
    } else {
        return
    }
})
window.addEventListener("scroll", () => {
    pixelDown();
})
arrow.addEventListener('click', () => {
    if (isMobileDevice()) {
        offsetAnchor()
    } else {
        if (window.innerHeight < 800) {
            offsetDown(650)
        } else {
            offsetDown(1000)
        }
    }
})